<template>
	<v-container>
		<div>
			<!-- :single-row="false" -->
			<s-crud
				:filter="filter"
				:config="config"
				search-input
				:remove="itemsSelected.PckInitialCharge == null"
				no-full
				height="auto"
				title="Embarque"
				ref="crudPackinglist"
				@rowSelected="rowSelected($event)"
				
			>
				<template v-slot:filter>
					<v-container>						
						<v-row style="margin-left: 5px" justify="center">

							<v-col cols="6" md="3" lg="3">
								<s-select-customer clearable label="Cliente" v-model="filter.UwoCustomerID"></s-select-customer>
							</v-col>
							<v-col cols="6" md="3" lg="3">
								<s-date
									label="Fecha Inicio"
									v-model="filter.cDateInitial"
								></s-date>
							</v-col>
							<v-col cols="6" md="3" lg="3">
								<s-date
									label="Fecha Fin"
									v-model="filter.cDateFin"
								></s-date>
							</v-col>
							<!-- <v-col cols="6" md="3" lg="3">
								<v-btn color="info" small class="mt-4" rounded @click="clickGeneratedShipment()">Generar Embarque</v-btn>
							</v-col> -->
						</v-row>
						<v-row style="margin-left: 5px">
							<v-col cols="6" md="3" lg="3">
								<s-select-definition
									label="Planta Procesadora"
									:def="1230"
									v-model="filter.TypePlantProcessing"
								></s-select-definition>
							</v-col>
						</v-row>
					</v-container>
				</template>

				
				<!-- <template v-slot:PckInitialShip="{ row }">
					<v-btn
						x-small
						:color="row.PckInitialCharge == null ? 'success' : 'warning'"
						rounded
						@click="clickInitial(row)"
						:disabled="row.PckFinCharge !== null"
					>
						{{row.PckInitialCharge == null ? 'Iniciar' : 'Terminar'}}
					</v-btn>
				</template> -->

				<template v-slot:PckView="{ row }">
					<v-btn
						x-small
						color="error"
						fab
						@click="generatedPrePacking(row, 1)"
					>
						<v-icon style="font-size: 16px !important"
							>fas fa-eye</v-icon
						>
					</v-btn>
				</template>
				<template v-slot:PckGenerated="{ row }">
					<v-btn
						x-small
						color="info"
						fab
						@click="generatedPrePacking(row, 2)"
					>
						<v-icon style="font-size: 16px !important"
							>fa fa-parking</v-icon
						>
					</v-btn>
				</template>
			</s-crud>

			<v-dialog persistent v-model="openModalPrePack" v-if="openModalPrePack" width="500">
				<prf-packing-rpt-index :item="itemsSelected" @close="closeAll()"></prf-packing-rpt-index>
			</v-dialog>

			<!-- MODAL PARA EDITAR PRE PACKING-->
			<v-dialog persistent v-model="modalEditPrePacking" v-if="modalEditPrePacking" width="500">
				<packing-pre-packing-edit :item="itemsSelected" @closeModalPrePackingEdit="closeModalPrePackingEdit()"></packing-pre-packing-edit>
			</v-dialog>
		</div>

	</v-container>
</template>

<script>
	//Service
	import _sPackingListService from "../../../services/FreshProduction/PackingListService";
	import _sPackingShipment from "../../../services/FreshProduction/PackingShipmentService";
	import sSelectCustomer from "../../../components/Sale/SselectClientSap.vue";
	import PrfPackingRptIndex from "./PrfPackingRptIndex.vue";
	import PackingPrePackingEdit from "./PackingPrePackingEdit.vue";

	export default {
		components: { sSelectCustomer, PrfPackingRptIndex, PackingPrePackingEdit },

		data: () => ({
			modalEditPrePacking: false,
			itemsSelected: [],
			itemsPrePacking: [],
			openModalPrePack: false,
			filter: {
				cDateInitial: "",
				cDateFin: ""
			},
			config: {
				service: _sPackingListService,
				model: {
					PckID: "ID",
					PckDate: "datetime",
					PckView: "",
					PckGenerated:"",
					PckInitialShip:""
				},
				headers: [
					/* { text: "Iniciar", value: "PckInitialShip", width: 10 }, */
					{ text: "Pre-Packing", value: "PckView", width: 10 },
					{ text: "Editar", value: "PckGenerated", width: 10 },
					{ text: "Pre Packing", value: "PckPrePackingNumber", width: 100 },
					{ text: "Pedido", value: "OrdID", width: 100 },
					{ text: "Cliente.", value: "UwoCustomerName", width: 340 },
					{ text: "Fecha", value: "PckDate", width: 140 }
				]
			},
		}),

		watch: {
		},

		methods: {


			closeAll(){
				this.openModalPrePack = false
				this.$refs.crudPackinglist.refresh();
			},

			rowSelected(items){

				if(items !== undefined){
					if(items.length > 0){
						this.itemsSelected = items[0];
						// console.log('rowselected ', this.itemsSelected);
					}
				}
				
			},



			generatedPrePacking(item, xFlag) {
				console.log('rpyt')
				this.itemsSelected = item
				_sPackingListService.getPrePacking(item.OrdID, this.$fun.getUserID()).then(resp => {
					
					if(resp.data.length > 0){
						resp.data.forEach(element => {
							element.PltDate = this.$moment(element.PltDate).format(this.$const.FormatDateDB);
							element.PrintDate = this.$moment(element.PrintDate).format(this.$const.FormatDateDB);
						});
					}
						

					this.itemsSelected.itemsDetail = resp.data
					this.itemsPrePacking = resp.data;
				})

				if(xFlag == 1){
					this.openModalPrePack  = true
				}

				if(xFlag == 2){
					this.modalEditPrePacking = true
				}
				
			},


			closeModalPrePackingEdit(){
				this.modalEditPrePacking = false
			},

			clickInitial(item){

				
				var message = "";

				if(item.PckInitialCharge == null){
					message = "¿Seguro de Inicar Embarque ?"
					item.SecStatus = 50
				}else{
					message = "¿Seguro de Terminar Embarque ?"
					item.SecStatus = 51
				}

				console.log("Iniciar", item);

				this.$fun.alert(message, "question").then((val) => {
                    if(val.value){
                        _sPackingShipment
								.save(item, this.$fun.getUserID())
								.then((resp) => {
                                    if(resp.status == 200){
                                        this.$fun.alert(
                                            "Actualizado correctamente",
                                            "success"
									    );
										this.$refs.crudPackinglist.refresh();
                                    }
									
								});
                    }
                })
				
			}


		},

	};
</script>
